import React, { useContext, useEffect, useState } from "react";
import "../styles/adminDashboard.css";
import {
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonButton,
  IonSpinner,
  IonCheckbox,
  IonList,
} from "@ionic/react";
import {
  closeCircle,
  filterOutline,
  optionsOutline,
  search,
} from "ionicons/icons";
import { useHistory, useLocation } from "react-router";
import PaginatedView from "./PaginatedView";
import {
  handleGrantPolicy,
  getAllDevices,
  getAllUsers,
  getFilteredDevices,
  getFilteredUsers,
  handleRevokePolicy,
  getAllPolicys,
  handleGrantThingPolicy,
  handlePolicyInfo,
} from "../service/adminUI";
import AppContext from "./AppContext";
import { useAppData } from "../context/appContext";
import { MdOutlineCancel } from "react-icons/md";

import * as _ from "lodash";

import { syncTimerFreqMode } from "../util/config";

const AdminDashboard = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [deviceResult, setDeviceResult] = useState([]);
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);
  const [isPolicyDataLoading, setIsPolicyDataLoading] = useState(false);
  const [isDeviceDataLoading, setIsDeviceDataLoading] = useState(false);
  const [revokeParams, setRevokeParams] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    appData,
    userData,
    userFilterData,
    deviceFilterData,
    setUserFilterData,
    setDeviceFilterData,
    userRecords,
    deviceRecords,
    setUserRecords,
    setDeviceRecords,
    dtalService,
  } = useContext(AppContext);
  const MODEL_TYPE_USER = "user";
  const MODEL_TYPE_DEVICE = "device";
  const FILTER_CONDITION_ANY = "or";
  const FILTER_CONDITION_ALL = "and";
  const [userEmail, setUserEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");
  const [policyId, setPolicyId] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [policy, setPolicy] = useState("");
  const [patient, setPatient] = useState("");
  const [clinician, setClinician] = useState("");
  const [originalDeviceResult, setOriginalDeviceResult] = useState([]);
  const [policyAsc, setPolicyAsc] = useState(true);
  const [patientAsc, setPatientAsc] = useState(true);
  const [serialNoAsc, setSerialNoAsc] = useState(true);
  const [clinicianAsc, setclinicianAsc] = useState(true);
  const [toastClass, setToastClass] = useState("toast toast-invisible");
  const [toastVisible, setToastVisible] = useState(false);
  const [message, setMessage] = useState("Loading..Please wait");
  const TOAST_SUCCESS_CSS = "toast toast-success";
  const TOAST_ERROR_CSS = "toast toast-error";
  const TOAST_INFO_CSS = "toast toast-info";

  const appContextData = useAppData();

  useEffect(async () => {
    appContextData.setSelected("Revoke");
    await handleLoadDevices();
  }, []);

  // useEffect(async () => {
  //     if (location.state) {
  //         const { modelType } = location.state;
  //         await handleLoadData(modelType);

  //         if (userRecords && userRecords.length > 0 && modelType != MODEL_TYPE_USER) {
  //             setUserResult(userRecords);
  //             setOriginalUserResult(userRecords);
  //         }
  //         if (deviceRecords && deviceRecords.length > 0 && modelType != MODEL_TYPE_DEVICE) {
  //             setDeviceResult(deviceRecords);
  //             setOriginalDeviceResult(deviceRecords);
  //         }
  //     }
  // }, [])

  const handleFilterOnClick = (modelName) => {
    history.push({ pathname: "/home/filter", state: { modelName } });
  };

  // const handleLoadData = async (modelType) => {
  //     if (modelType == MODEL_TYPE_USER) {
  //         await handleLoadUsers();
  //     } else if (modelType == MODEL_TYPE_DEVICE) {
  //         await handleLoadDevices();
  //     } else {
  //         await handleLoadUsers();
  //         await handleLoadDevices();
  //     }
  // }

  const handleLoadDevices = async (isStaleExpression) => {
    let deviceData = null;
    let newDeviceExpression = "";
    setIsDeviceDataLoading(true);
    if (isStaleExpression == true) {
      newDeviceExpression = setFilterExpression(MODEL_TYPE_DEVICE);
    } else {
      newDeviceExpression = deviceFilterData.deviceFilterExpression;
    }
    if (
      deviceFilterData.deviceFilters &&
      !(deviceFilterData.deviceFilters.length > 0)
    ) {
      deviceData = await getAllDevices(appData.accessToken);
    } else {
      //await getFilteredDevices(appData.accessToken, 'eq(attributes/serialnumber,"AAA089010AAB")');
      deviceData = await getFilteredDevices(
        appData.accessToken,
        newDeviceExpression
      );
    }
    const allUserPolicies = await getAllUserPolicies(deviceData);
    if (allUserPolicies) {
      setDeviceResult(allUserPolicies);
      setOriginalDeviceResult(allUserPolicies);
      setDeviceRecords(allUserPolicies);
    } else {
      setDeviceRecords([]);
      autoHideToast(TOAST_INFO_CSS, "Devices search: No data received");
    }
    setIsDeviceDataLoading(false);
  };

  const getAllUserPolicies = async (deviceData) => {
    const allUserPolicies = [];
    if (deviceData && deviceData.length > 0) {
      for (const data of deviceData) {
        const policyId = data.policyId ? data.policyId : "";
        const serialnumber =
          data.attributes && data.attributes.serialnumber
            ? data.attributes.serialnumber
            : "";
        let patient = "";
        let clinician = "";
        if (data.policyId) {
          const policyInfo = await handlePolicyInfo(
            appData.accessToken,
            data.policyId
          );
          for (let val in policyInfo.entries) {
            //console.log('val:::'+val)
            let entry = policyInfo.entries[val];
            let entryObj = Object.values(entry.subjects)[0];
            if (serialnumber == "AAB32145AAA")
              console.log(`entry:::${JSON.stringify(entryObj)}, val:::${val}`);
            if (entryObj.type && entryObj.type === "patient") {
              patient = val;
              // console.log('val:::'+val)
            } else if (entryObj.type && entryObj.type === "clinician")
              clinician = val;
          }
        }
        //console.log('policyId:::'+policyId)
        allUserPolicies.push({ policyId, serialnumber, patient, clinician });
      }
    }

    return allUserPolicies;
  };

  const setFilterExpression = (model) => {
    let filterExpression = "";
    let filters = null;
    model == MODEL_TYPE_USER
      ? (filters = userFilterData.userFilters)
      : (filters = deviceFilterData.deviceFilters);

    //get all data when no filter selected
    if (filters == null || !(filters.length > 0)) {
      filterExpression = "";
    } else {
      if (filters.length == 1) {
        // no prefix for single expression
        filterExpression = filters[0].id;
      } else {
        for (let i = 0; i < filters.length; i++) {
          filterExpression = `${filterExpression}${filters[i].id},`;
        }
        filterExpression = filterExpression.slice(0, -1);
        if (model == MODEL_TYPE_USER) {
          //append and-or condition prefix in expression
          filterExpression =
            (userFilterData.userFilterExpression.startsWith(
              FILTER_CONDITION_ANY
            )
              ? FILTER_CONDITION_ANY
              : FILTER_CONDITION_ALL) + `(${filterExpression})`;
        } else if (model == MODEL_TYPE_DEVICE) {
          //append and-or condition prefix in expression
          filterExpression =
            (deviceFilterData.deviceFilterExpression.startsWith(
              FILTER_CONDITION_ANY
            )
              ? FILTER_CONDITION_ANY
              : FILTER_CONDITION_ALL) + `(${filterExpression})`;
        }
      }
    }
    //set app context for the filters and expressions
    model == MODEL_TYPE_USER
      ? setUserFilterData({
          ...userFilterData,
          userFilterExpression: filterExpression,
        })
      : setDeviceFilterData({
          ...deviceFilterData,
          deviceFilterExpression: filterExpression,
        });
    return filterExpression;
  };

  const handlePolicyFilter = (event) => {
    const value = event.currentTarget.value;
    setPolicy(value);
    const data = originalDeviceResult.filter(
      (res) =>
        res.policyId && res.policyId.toLowerCase().includes(value.toLowerCase())
    );
    setDeviceResult(data);
  };

  const handlePatientFilter = (event) => {
    const value = event.currentTarget.value;
    setPatient(value);
    const data = originalDeviceResult.filter(
      (user) =>
        user.patient && user.patient.toLowerCase().includes(value.toLowerCase())
    );
    setDeviceResult(data);
  };

  const handleClinicianFilter = (event) => {
    const value = event.currentTarget.value;
    setClinician(value);
    const data = originalDeviceResult.filter(
      (user) =>
        user.clinician &&
        user.clinician.toLowerCase().includes(value.toLowerCase())
    );
    setDeviceResult(data);
  };

  const handleSerialNoFilter = (event) => {
    const value = event.currentTarget.value;
    setSerialNo(value);
    const data = originalDeviceResult.filter((device) =>
      device.serialnumber.includes(value)
    );
    setDeviceResult(data);
  };
  const handleSerialNoSort = () => {
    let data;
    if (serialNoAsc) {
      data = deviceResult.sort((a, b) => a.policyId.localeCompare(b.policyId));
    } else {
      data = deviceResult.sort((a, b) => b.policyId.localeCompare(a.policyId));
    }
    setSerialNoAsc(!serialNoAsc);
    setDeviceResult([...data]);
  };
  const handlePolicySort = () => {
    let data;
    if (policyAsc) {
      data = deviceResult.sort((a, b) => a.policyId.localeCompare(b.policyId));
    } else {
      data = deviceRecords.sort((a, b) => b.policyId.localeCompare(a.policyId));
    }
    setPolicyAsc(!policyAsc);
    setDeviceResult([...data]);
  };

  const handlePatientSort = () => {
    let data;
    if (patientAsc) {
      data = deviceResult.sort((a, b) => a.patient.localeCompare(b.patient));
    } else {
      data = deviceResult.sort((a, b) => b.patient.localeCompare(a.patient));
    }
    setPatientAsc(!patientAsc);
    setDeviceResult([...data]);
  };

  const handleClinicianSort = () => {
    let data;
    if (clinicianAsc) {
      data = deviceResult.sort((a, b) =>
        a.clinician.localeCompare(b.clinician)
      );
    } else {
      data = deviceResult.sort((a, b) =>
        b.clinician.localeCompare(a.clinician)
      );
    }
    setclinicianAsc(!clinicianAsc);
    setDeviceResult([...data]);
  };

  //Auto Hide toast after 5 seconds
  const autoHideToast = (css, message) => {
    setToastClass(css);
    setMessage(message);
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 5000);
  };

  return (
    <IonContent>
      <div className="search-box">
        <IonLabel className="table-heading">All Policies and Users</IonLabel>
      </div>

      <div>
        {isDeviceDataLoading ? (
          <div className="spinner-box">
            <IonSpinner name="lines" className="ion-spinner" />
            <IonLabel className="row-header">
              Please wait, search is in progress
            </IonLabel>
          </div>
        ) : (
          <IonGrid className="container-grid">
            <IonRow className="row-header">
              {/* <IonCol className="table-header-cell"/> */}
              <IonCol className="table-header-cell">
                <IonItem className="row-box">
                  <IonInput
                    value={serialNo}
                    placeholder="Serial Number"
                    onIonChange={handleSerialNoFilter}
                  ></IonInput>
                  <IonIcon
                    icon={filterOutline}
                    slot="end"
                    onClick={handleSerialNoSort}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="table-header-cell">
                <IonItem className="row-box">
                  <IonInput
                    value={policyId}
                    placeholder="Policy Id"
                    onIonChange={handlePolicyFilter}
                  ></IonInput>
                  <IonIcon
                    icon={filterOutline}
                    slot="end"
                    onClick={handlePolicySort}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="table-header-cell">
                <IonItem className="row-box">
                  <IonInput
                    value={patient}
                    placeholder="Patient Email"
                    onIonChange={handlePatientFilter}
                  ></IonInput>
                  <IonIcon
                    icon={filterOutline}
                    slot="end"
                    onClick={handlePatientSort}
                  />
                </IonItem>
              </IonCol>
              <IonCol className="table-header-cell">
                <IonItem className="row-box">
                  <IonInput
                    value={clinician}
                    placeholder="Clinician Email"
                    onIonChange={handleClinicianFilter}
                  ></IonInput>
                  <IonIcon
                    icon={filterOutline}
                    slot="end"
                    onClick={handleClinicianSort}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <PaginatedView userData={deviceResult} pageSize={20}>
              <IonList>
                {deviceResult.map((item) => {
                  return (
                    <IonRow className="devices-row">
                      {/* <IonCol className="table-cell radio-button"> */}
                      {/* </IonCol> */}
                      <IonCol className="table-cell">
                        <IonButton
                          value={item.patient + "," + item.clinician}
                          onClick={() => {
                            setOpenModal(true);
                          }}
                        >
                          Revoke
                        </IonButton>
                        &nbsp;
                        {item.serialnumber}
                      </IonCol>
                      <IonCol className="table-cell">{item.policyId}</IonCol>
                      <IonCol className="table-cell">{item.patient}</IonCol>
                      <IonCol className="table-cell">{item.clinician}</IonCol>
                    </IonRow>
                  );
                })}
              </IonList>
            </PaginatedView>
          </IonGrid>
        )}

        {openModal == true ? (
          <div
            style={{
              position: "absolute",
              height: "300px",
              width: "700px",
              zIndex: "100",
              background: "#E6E6E6",
              border: "1px solid black",
              left: "16px",
              right: "10%",
              marginLeft: "auto",
              marginRight: "auto",
              bottom: "19%",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "60px",
                background: "#4D4D4D",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ color: "#E6E6E6" }}>Revoke Permissions</div>
              <div
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <MdOutlineCancel
                  style={{ fontSize: "30px", color: "#E6E6E6" }}
                />
              </div>
            </div>
            <div style={{ padding: "20px", marginTop: "5px" }}>
              <div
                style={{
                  fontSize: "17px",
                  color: "#535352",
                  paddingTop: "0px",
                  paddingBottom: "15px",
                }}
              >
                Choose Users to Revoke Permissions
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // handle patient email selection
                  }}
                >
                  <IonCheckbox
                    checked={revokeParams.patientEmailSelected}
                    onIonChange={(e) => {
                      setRevokeParams({
                        ...revokeParams,
                        patientEmailSelected: e.detail.checked,
                      });
                    }}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    Patient Email: {revokeParams.patientEmail}
                  </span>
                </div>
                <div
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    // handle clinician email selection
                  }}
                >
                  <IonCheckbox
                    checked={revokeParams.clinicianEmailSelected}
                    onIonChange={(e) => {
                      setRevokeParams({
                        ...revokeParams,
                        clinicianEmailSelected: e.detail.checked,
                      });
                    }}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    Clinician Email: {revokeParams.clinicianEmail}
                  </span>
                </div>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#666",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                Are you sure you want to Revoke permissions for selected users?
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IonButton
                  style={{ width: "120px" }}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </IonButton>
                <IonButton
                  style={{ width: "120px" }}
                  onClick={() => {
                    // handleRevokePolicy(
                    //   revokeParams.patientEmailSelected
                    //     ? revokeParams.patientEmail
                    //     : null,
                    //   revokeParams.clinicianEmailSelected
                    //     ? revokeParams.clinicianEmail
                    //     : null,
                    //   bearerToken,
                    //   emailId,
                    //   type
                    // );
                  }}
                >
                  Proceed
                </IonButton>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </IonContent>
  );
};
export default AdminDashboard;
