import registerPolicyModel from '../model/registerPolicyModel.js';
import {grantPolicyModel, grantRdonlyPolicyModel} from "../model/grantPolicyModel";
import revokePolicyModel from "../model/revokePolicyModel";
import { baseurl, dittoauthcode, clientID } from "../util/config";
import jwt_decode from "jwt-decode";
import {useContext} from "react";
import AppContext from "../components/AppContext";



const USER_MGMT_URL = baseurl + 'auth/realms/calyan/protocol/openid-connect/token'
const USER_INFO_URL = baseurl + `auth/admin/realms/calyan/users`
const DT_MGMT_URL = baseurl; 
const CLIENT_ID = clientID;
//const CLIENT_SECRET = '5b4f5ce8-abca-417e-953a-dd3b485370bc'
//const CLIENT_SECRET = '6875b646-24f9-47db-b706-201210d5a72c'
const CLIENT_SECRET = dittoauthcode;
const OAUTH_URL = baseurl + 'auth/admin/calyan/console/'
const USERS_URL = baseurl + 'auth/admin/realms/calyan/users/'
const LOGIN_ACTION_URL = baseurl + 'auth/realms/calyan/account/'
const LOGOUT_URL = baseurl + 'auth/realms/calyan/protocol/openid-connect/logout'
const DEVICE_URL = baseurl + 'api/2/search/things'

const REGISTER_POLICIES_URL = baseurl + 'api/2/policies/'
const REGISTER_DEVICE_URL = baseurl + 'api/2/things/'
const ERROR_MSG_500 = 'The request ran out of time to execute on the the back-end. Optimize your query and try again.';
//#region Login

  //utility function to decode jwt access token
export  const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

/** POST call for login
 ReqBody - {username, password}
 */
export const handleLogin = async (username, password, otp='0') => {

    var details = {
        'client_id': 'dittoauth',
        'grant_type': 'password',
        'client_secret': CLIENT_SECRET,
        'scope': 'openid',
        'username': username.trim(),
        'password': password.trim(),
        'totp': otp.trim()
    };

    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const url = USER_MGMT_URL;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
    };

    const result = await fetch(url, requestOptions);
    if (result.status == 400) {
        return 400;
    } else if (result.status == 401) {
        return 401;
    } else {
        const value = await result.json();
        const decodedToken=jwt_decode(value.access_token)
        //console.log(decodedToken.type,"ye decoded token hai")
        localStorage.setItem("clinicianId",decodedToken.sub)
        localStorage.setItem("type",decodedToken.type)
        return value;
    }
}

/** GET call for userinfo with self userid
 ReqBody - {refresh_token}
 */
export const getUserInfo = async(bearer_token) => {
    const userId = localStorage.getItem("clinicianId")
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token,
        },
       
    };
    const userInfo = await (await fetch(`${USER_INFO_URL}/${userId}`,requestOptions)).json()
    //console.log(userInfo,"this is the user info")
    return userInfo
}

/** GET call for userinfo with another userid
 ReqBody - {refresh_token}
 */
 export const getUserInfoById = async(bearer_token, userId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token,
        },
       
    };
    const userInfo = await (await fetch(`${USER_INFO_URL}/${userId}`,requestOptions)).json()
    //console.log(userInfo,"this is the user info")
    return userInfo
}

/** GET call for userinfo with usernname e.g emailId
 ReqBody - {refresh_token}
 */
 export const getUserInfoByName = async(bearer_token, emailId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token,
        },
       
    };
    const userInfo = await (await fetch(`${USER_INFO_URL}?username=${emailId}`,requestOptions)).json()
    //console.log(userInfo,"this is the user info")
    return userInfo?userInfo[0]:null  //return the first element of the response data
}

/** POST call for refreshToken
 ReqBody - {refresh_token}
 */
export const handleRefreshToken = async (refresh_token) => {


    var details = {
        'client_id': 'dittoauth',
        'grant_type': 'refresh_token',
        'client_secret': CLIENT_SECRET,
        'refresh_token': refresh_token
    };

    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const url = USER_MGMT_URL;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
    };

    const result = await fetch(url, requestOptions);

    if (result.status == 400) {
        return 400;
    } else if (result.status == 401) {
        return 401;
    } else {
        const value = await result.json();
        return value;
    }
}

/** POST call for logout
 ReqBody - {userId}
 */
export const handleLogout = async (userId, refresh_token, bearer_token) => {

    const url = LOGOUT_URL;

    var details = {
        'client_id': 'dittoauth',
        'client_secret': CLIENT_SECRET,
        'refresh_token': refresh_token
    };

    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': 'Bearer ' + bearer_token
        },
        body: formBody
    };

    const result = await fetch(url, requestOptions);
    if ((result.ok) && (result.status === 204)) {
        return 204;
    } else {
        const value = await result.json();
        return value;
    }
}

//#endregion

//#region Registration

/*
 * PUT call for: Register Policy for device
*/
export const handleRegisterPolicy = async (macId, bearer_token) => {
    const decoded = parseJwt(bearer_token);
    const policyOwner = "calyan:" + `${decoded.sub}`;
    const policyId = `pacemaker:${macId}-policy`;
    const url = REGISTER_POLICIES_URL + policyId;
    const subjectVal = {
        [policyOwner]:
            {
                "type": decoded.type
            }
    } 

    registerPolicyModel.policyId = policyId;
    registerPolicyModel.entries.DEFAULT.subjects = subjectVal;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        },
        body: JSON.stringify(registerPolicyModel)
    };
    const result = await fetch(url, requestOptions);
    if ((result.ok) && ((result.status == 204) || (result.status == 201))) {
        return true;
    } else {
        const value = await result.json();
        return value;
    }
}

/*
 * PUT call for: Register Policy for a thing (other than device)
*/
export const handleRegisterThingPolicy = async (identifier, bearer_token, policyowner='') => {

    const policyId = `${identifier}-policy`;
    const url = REGISTER_POLICIES_URL + policyId;
    registerPolicyModel.policyId = policyId;

    if(Object.keys(policyowner).length !== 0) {
        let ownerid=`calyan:${policyowner.userId}`;
        registerPolicyModel.entries.DEFAULT.subjects = {
            [ownerid]: {
                "type": policyowner.type
            }
        }
    }

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        },
        body: JSON.stringify(registerPolicyModel)
    };
    const result = await fetch(url, requestOptions);
    if ((result.ok) && ((result.status == 204) || (result.status == 201))) {
        return true;
    } else {
        const value = await result.json();
        return value;
    }
}

/*
 * PUT call for: Register Device
*/
export const handleRegisterDevice = async (macId, model, bearer_token) => {
    //register policy
    const policyId = `pacemaker:${macId}-policy`;
    const resultPolicy = await handleRegisterPolicy(macId, bearer_token);

    if (resultPolicy == true) {
        const thingId = `pacemaker:${macId}`;
        const url = REGISTER_DEVICE_URL + thingId;
        model.thingId = thingId;
        model.policyId = policyId;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + bearer_token
            },
            body: JSON.stringify(model)
        }
        const result = await fetch(url, requestOptions);
        if ((result.ok) && ((result.status == 204) || (result.status == 201))) {
            return true;
        } else {
            const value = await result.json();
            return result.status == 400 ? ERROR_MSG_500 : value.errorMessage;
        }
    } else
        return resultPolicy;
}

/*
 * PUT call for: Register Thing (other than device)
*/
export const handleRegisterThing = async (identifier, model, bearer_token, policyowner={}) => {
    //register policy
    const policyId = `${identifier}-policy`;
    const resultPolicy = await handleRegisterThingPolicy(identifier, bearer_token, policyowner);

    if (resultPolicy == true) {
        const thingId = identifier;
        const url = REGISTER_DEVICE_URL + thingId;
        model.thingId = thingId;
        model.policyId = policyId;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + bearer_token
            },
            body: JSON.stringify(model)
        }
        const result = await fetch(url, requestOptions);
        if ((result.ok) && ((result.status == 204) || (result.status == 201))) {
            return true;
        } else {
            const value = await result.json();
            return result.status == 400 ? ERROR_MSG_500 : value.errorMessage;
        }
    } else
        return resultPolicy;
}
/*
 * POST call for: Register User
*/
export const handleRegisterUser = async (type, model, bearer_token,userid='') => {
    let url = USERS_URL;
    model.attributes.type = type;
    let requestOptions = {}
    if(userid != '') {
        requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + bearer_token
            },
            body: JSON.stringify(model)
        };        
        url += userid;
    } else {
        requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + bearer_token
            },
            body: JSON.stringify(model)
        };
    }
    const result = await fetch(url, requestOptions);
    if ((result.ok) && ((result.status == 204) || (result.status == 201))) {
        return true;
    } else {
        const value = await result.json();
        return result.status == 400 ? ERROR_MSG_500 : value.errorMessage;
    }
}

/*
 * GET call for: getting all users
*/
export const getAllUsers = async (bearer_token) => {
    const url = USERS_URL;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        }
    };
    const result = await fetch(url, requestOptions);
    const value = await result.json();
    return value;
}

/*
 * GET call for: getting all subjects
*/
export const getAllSubjects = async (bearer_token) => {
    const url = DEVICE_URL+'?namespaces=eCRF_subjects';
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        }
    };
    const result = await fetch(url, requestOptions);
    const value = await result.json();
    return value.items;
}

/*
 * GET call for: getting all devices
*/
export const getAllDevices = async (bearer_token) => {
    const url = DEVICE_URL+'?namespaces=pacemaker';
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        }
    };
    const result = await fetch(url, requestOptions);
    const value = await result.json();
    return value.items;
}

/*
 * GET call for: get filtered users
*/
export const getFilteredUsers = async (bearer_token, filter) => {
    const url = USERS_URL + '?search=' + filter;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        }
    };
    const result = await fetch(url, requestOptions);
    const value = await result.json();
    //console.table(value);
    //console.log(value);
    return value;
}

/*
 * GET call for: get filtered devices
*/
export const getFilteredDevices = async (bearer_token, filter) => {
    const url = DEVICE_URL + '?namespaces=pacemaker&filter=' + filter;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        }
    };
    const result = await fetch(url, requestOptions);
    const value = await result.json();
    //console.log(value);
    return value.items;
}

export const handlePolicyInfo = async (token, policyId) => {
    const url = REGISTER_POLICIES_URL + policyId;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    const result = await fetch(url, requestOptions);
    const value = await result.json();
    //console.log(value);
    return value;
}

/*
 * PUT call for: grant policy
*/
export const handleGrantPolicy = async (macId, userId, bearer_token, emailId, type) => {
    const policyId = `pacemaker:${macId}-policy`;
    const thingVal = "calyan:" + `${userId}`;
    const url = REGISTER_POLICIES_URL + policyId + '/entries/' + emailId;
    const subjectVal = {
        [thingVal]:
            {
                "type": type
            }
    }
    grantPolicyModel.subjects = subjectVal;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        },
        body: JSON.stringify(grantPolicyModel)
    };
    const result = await fetch(url, requestOptions);

    if(result.status == 201) {
        const value = await result.json();
        console.log(value);
        return { status:201, message: "Device assigned"};
    }
    else if(result.status == 204) {
        return { status:204, message: "Device already assigned"}
    }
    else {
        const value = await result.json();
        return result.status == 400 ? {status:400, message: ERROR_MSG_500} :
            {status: result.status,
                message: value.errorMessage?value.errorMessage:"Device can't be assigned"};
    }
}

/*
 * DELETE call for: revoke policy
*/
export const handleRevokePolicy = async (macId, userId, bearer_token, emailId, type) => {
    const policyId = `pacemaker:${macId}-policy`;
    const thingVal = "calyan:" + `${userId}`;
    const url = REGISTER_POLICIES_URL + policyId + '/entries/' + emailId;
    const subjectVal = {
        [thingVal]:
            {
                "type": type
            }
    }
    revokePolicyModel.subjects = subjectVal;

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        },
        body: JSON.stringify(revokePolicyModel)
    };
    const result = await fetch(url, requestOptions);
    return result.status == 204?{ status:204, message: "Device unassigned"}:
        { status:501, message: "Device can't be unassigned"};
}

/*
 * PUT call for: grant policy
*/
export const handleGrantThingPolicy = async (identifier, userId, bearer_token, emailId, type, isRW) => {
    const policyId = `${identifier}-policy`;
    const thingVal = "calyan:" + `${userId}`;
    const url = REGISTER_POLICIES_URL + policyId + '/entries/' + emailId;
    const subjectVal = {
        [thingVal]:
            {
                "type": type
            }
    }
    let policy = {};
    if(isRW) {
        policy = grantPolicyModel;
        policy.subjects = subjectVal;
    } else {
        policy = grantRdonlyPolicyModel;
        policy.subjects = subjectVal;        
    }

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        },
        body: JSON.stringify(policy)
    };
    const result = await fetch(url, requestOptions);

    if(result.status == 201) {
        const value = await result.json();
        console.log(value);
        return { status:201, message: "Device assigned"};
    }
    else if(result.status == 204) {
        return { status:204, message: "Device already assigned"}
    }
    else {
        const value = await result.json();
        return result.status == 400 ? {status:400, message: ERROR_MSG_500} :
            {status: result.status,
                message: value.errorMessage?value.errorMessage:"Device can't be assigned"};
    }
}

/*
 * DELETE call for: revoke policy
*/
export const handleRevokeThingPolicy = async (identifier, userId, bearer_token, emailId, type) => {
    const policyId = `${identifier}-policy`;
    const thingVal = "calyan:" + `${userId}`;
    const url = REGISTER_POLICIES_URL + policyId + '/entries/' + emailId;
    const subjectVal = {
        [thingVal]:
            {
                "type": type
            }
    }
    revokePolicyModel.subjects = subjectVal;

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer_token
        },
        body: JSON.stringify(revokePolicyModel)
    };
    const result = await fetch(url, requestOptions);
    return result.status == 204?{ status:204, message: "Device unassigned"}:
        { status:501, message: "Device can't be unassigned"};
}

//#endregion


