import React, { useEffect, useCallback, useState } from 'react'
import { BsFillPersonFill } from "react-icons/bs"
import { useAppData } from '../../context/appContext'
import { IonButton, IonGrid, IonImg, IonInput, IonTextarea, IonRow, IonCol, IonItem, IonNote, IonSpinner, IonIcon, IonLabel} from '@ionic/react';
import DTAL from '../../service/devicesubscription/mockdataservice';
// import addfiles from "../../assets/icons/addfiles.svg"
import "./messages.css"
import moment from 'moment';
//firebase
import { storage } from '../../config/firebase';
import { ref, uploadBytes, listAll, getDownloadURL, uploadBytesResumable,deleteObject } from "firebase/storage"
import { AiOutlineFileImage } from "react-icons/ai"
import { MdOutlineCancel } from "react-icons/md"
import { MdOutlineAttachEmail } from "react-icons/md"
import { useContext } from 'react';
import AppContext from '../../components/AppContext';

import { getHistoricalData } from '../../util/helpers/HistoricalData';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ionicons/icons'

let patientRxMessages = new Map();   //messages received by Patient sent by logged doctor
let clinicianRxMessages = new Map(); //messages received by logged in doctor from selected patient
let fileUploaded = new Map(); //maintain file name and download url
const patientRxControlSubject = new BehaviorSubject(false); //to emit start processing of message updates;
const clinicianRxControlSubject = new BehaviorSubject(false); //to emit start processing of message updates;

let historicalPatientMsgSubscription, patientRxControlSubscription,clinicianRxControlSubscription,historicalClinicianMsgSubscription;
let selectedPatient
const Messages = ({ selectedPatientSubject }) => {

    let stringPatientData = localStorage.getItem("patientData")
    let patients = JSON.parse(stringPatientData)
    const appData = useAppData()
    const [imageUpload, setImageUpload] = useState([])
    const [images, setImages] = useState()
    const [selectedPatient, setSelectedPatient] = useState()
    const [messageArray, setMessageArray] = useState()
    const [plaintext, setPlainText] = useState(null)
/*     const [imageUrl, setImageUrl] = useState() */
    const [userId, setUserId] = useState()
    const [uploadPercent, setUploadPercent] = useState(0)
    const [fileList, setFileList] = useState([])    

    const [data, setData] = useState({
        plaintext: "",
        filelinks: [],
        senderId: "",
    })
    const [isLoaded,setIsLoaded] = useState(false)
    const [isMsgAvailable,setIsMsgAvailable] = useState(true)
    
    const {dtalService,userData} = useContext(AppContext)


    var sentmessages = null; 
    var receivedmessages = null;
    const allowedImageType = ['image/jpg', 'image/jpeg', 'image/png']
    const imageListRef = ref(storage, "images/")
    const uploadImage = async () => {
        const promises = []
        images.map((image) => {
            const imageRef = ref(storage, `images/${image.name}`)
            promises.push(uploadBytes(imageRef, image))
        })

    }
    const getMessage = async (subjectId) => {
        //let user = localStorage.getItem("clinician")
        let modifiedMessageArray = []

        if(subjectId.length) 
        {
            setIsLoaded(false);
            setMessageArray([]);
            patientRxMessages.clear();
            clinicianRxMessages.clear();
            patientRxControlSubscription?.unsubscribe();
            clinicianRxControlSubscription?.unsubscribe();

            //get messages sent to selected patient 
            historicalPatientMsgSubscription = getHistoricalData({
                dtalService,
                identifier: "message:"+subjectId,
                thingUpdates: patientRxMessages,
                duControlSubject:patientRxControlSubject,
                //fields: "thingId,attributes,features,_revision,_modified&filter=like(senderId,\""+userData.userId+"\")"
            })
        }

        patientRxControlSubscription = patientRxControlSubject.subscribe(isPatientMsgAvailable => {
            if (isPatientMsgAvailable) {
                sentmessages = Array.from(patientRxMessages.values());
                dtalService.unsubscribe('history').then(resp => { });
                //app level filtering to get patientRx messages with sender as the logged in doctor
                sentmessages = _.filter(sentmessages,function (o) { return o.features?.content.properties.senderId == userData.userId; });

            //get messages from selected patient
            historicalClinicianMsgSubscription = getHistoricalData({
                dtalService,
                identifier: "message:"+userData.userId,
                thingUpdates: clinicianRxMessages,
                duControlSubject:clinicianRxControlSubject,
                //fields: "thingId,attributes,features,_revision,_modified&filter=like(senderId,\""+subjectId+"\")"
            })   
            
            patientRxControlSubject.next(false); //reset to enable next history of patientRxMessages
            
            } 
        })

        clinicianRxControlSubscription = clinicianRxControlSubject.subscribe(isClinicianMsgAvailable => {
            if (isClinicianMsgAvailable) {
                receivedmessages = Array.from(clinicianRxMessages.values());
                dtalService.unsubscribe('history').then(resp => { });
                //app level filtering to get logged in doctor messages with sender as the selected patient
                receivedmessages = _.filter(receivedmessages,function (o) { return o.features?.content.properties.senderId == subjectId; });

                for (var i = 0; i < sentmessages?.length; i++) {
                    if(sentmessages[i].features) {
                    let timestampsender = moment(sentmessages[i]._modified).unix()
                    sentmessages[i]["timestamp"] = timestampsender
                    sentmessages[i]["role"] = "clinician"
                    modifiedMessageArray.push(sentmessages[i])
                    }
        
                }
                for (var j = 0; j < receivedmessages?.length; j++) {
                    if(receivedmessages[j].features) {
                    let timestampreceived = moment(receivedmessages[j]._modified).unix()
                    receivedmessages[j]["timestamp"] = timestampreceived
                    receivedmessages[j]["role"] = "patient"
                    modifiedMessageArray.push(receivedmessages[j])
                    }
                }
                let sortedArray = modifiedMessageArray.sort(
                    (p1, p2) => (p1.timestamp > p2.timestamp) ? 1 : (p1.timestamp < p2.timestamp) ? -1 : 0);
                setMessageArray(sortedArray)

                clinicianRxControlSubject.next(false); //reset to enable next history of clinicianRxMessages
                
                setIsLoaded(true); //set to indicate the data is ready
                if(sortedArray.length == 0) {
                   setIsMsgAvailable(false)     
                } else {
                    setIsMsgAvailable(true)
                }
            
            } 
        })        

        //const sentmessages = await DTAL.gethistorical(`message:${subjectId}&filter=like(senderId,"userId")`)
        //const receivedmessages = await DTAL.gethistorical(`message:${user}&filter=like(senderId,${subjectId})`)


    }

    useEffect(() => {
        appData.setSelected("Messages")
        let userId = localStorage.getItem("clinicianId")
        setUserId(userId)
        if (appData?.appUserData == null) {
/*             getMessage(patients[0]?.attributes.subjectId)
            setSelectedPatient(patients[0]?.attributes.subjectId) */
        } else {
            getMessage(appData?.appUserData.attributes.subjectId)
            setSelectedPatient(appData?.appUserData.attributes.subjectId)
        }

        //getMessage(selectedPatient)
/*         let urlArray = []
        listAll(imageListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    console.log(url, "these are the urls")
                    urlArray.push(url)
                    setImageUrl(urlArray)
                })
            })
        }) */

        return () => {
            dtalService.unsubscribe('history').then(resp => { });
            historicalPatientMsgSubscription?.then(sub => sub.unsubscribe());
            patientRxControlSubscription?.unsubscribe();
            clinicianRxControlSubscription?.unsubscribe();
            historicalClinicianMsgSubscription?.then(sub => sub.unsubscribe());;
      
          };

    }, [])

    

    const createMessage = async () => {
        let data={ features:{
            content:{
                properties:{
                    plaintext: plaintext,
                    senderId: userId
                }
            }
        }}
        if(fileUploaded.size) {
            data.features.content.properties.filelinks = Array.from(fileUploaded.values());
        }

        //rather than create do set TODO
        let response = await dtalService.set(`message:${selectedPatient}`, data)

        let sentMessage = {};
        sentMessage["timestamp"] = Date.now()/1000;
        sentMessage["role"] = "clinician"
        sentMessage.features = data.features;
        setMessageArray([...messageArray, sentMessage])

        setPlainText(null); //clear the message text
        setFileList([]);

        // setPlainText(null)
    }


    const handleFileUpload = (e) => {
        let files = Array.from(e.target.files);
        files.map((file, index) => {
        const storageRef = ref(storage, `/files/${file.name}`);
 
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);
 
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
 
                // update progress
                setUploadPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    //setImageUpload([...imageUpload, url])
                    fileUploaded.set(file.name,url);
                    setFileList([...fileList,file.name])
                    
                });
            }
        );     
        })   
    }

    const handleFileDelete= (selectedFile) => {
      
        const storageRef = ref(storage,fileUploaded.get(selectedFile));
        deleteObject(storageRef).then(() => {
            fileUploaded.delete(selectedFile);
            setFileList(Array.from(fileUploaded.keys()));
          }).catch((error) => {
            // Failed, 
          }); 

    }

    return (
        <div className='messages_container' >
            <div className='messages_patient_container_height'>
                {patients.map((item, index) => {
                    return (
                        <div data-testid={"TPATIENTSNAME_"+index} className='messages_patient_list' onClick={() => {
                            setSelectedPatient(item.attributes.subjectId)
                            getMessage(item.attributes.subjectId)
                        }} key={index} >
                            <div>
                                <BsFillPersonFill className={selectedPatient == item.attributes.subjectId ? "selected_patient_icon" : "patient_icon"} />
                            </div>
                            <div className={selectedPatient == item.attributes.subjectId ? "selected_patient_text" : "patient_text"}>
                                {item.attributes.subjectId}
                            </div>
                        </div>
                    )
                })}

            </div>
            {selectedPatient && <div className='messages_box_container'>
                <div className='messages_container_height'>
                    <div>
                    {!isLoaded && <IonSpinner name="crescent"> </IonSpinner>}
{/*                     {<IonLoading message={"Fetching messages for " + selectedPatient} isOpen={!isLoaded} > </IonLoading>} */}
                    {!isMsgAvailable && <IonLabel value={"No messages found for" + selectedPatient}> </IonLabel>}
                    <IonGrid>                    
                        {messageArray?.map((item, index) => {
                            return (
                                <>
{/*                                     <div className={item.role == "clinician" ? "clinician" : "patient"} key={index}>
                                        {item.features?.content.properties.plaintext ?? "Empty Message"}
                                    </div>
                                    <div className={item.role == "clinician" ? "cmsgts" : "pmsgts"}> {new Date(item.timestamp*1000).toLocaleString()}
                                    </div>   */}   

                                        <IonRow>
                                         <IonCol size="6" offset={item.role == "clinician" ? "0" : "6"}>
                                            <IonItem color="medium" lines="none">
                                            <IonTextarea className={item.role == "clinician" ? "ciontextarea" : "piontextarea"}  value={item.features?.content.properties.plaintext ?? "Empty Message"}></IonTextarea>
                                            <IonNote  slot="end">{new Date(item.timestamp*1000).toLocaleString()}</IonNote>
                                            </IonItem>
                                            <div>
                                        
                                            {
                                            item.features?.content.properties.filelinks? item.features.content.properties.filelinks.map((item, index) => {
                                                let splitPaths = item.split('/');  
                                                let lastPath = splitPaths[splitPaths.length-1].split('?');
                                                let filename = lastPath[0];
                                                let filetype = (filename.indexOf(".pdf") == -1)?"png":"pdf"

                                                return (
                                                    <IonItem color="light"  lines="none" >
                                                        <FontAwesomeIcon icon={filetype=="png"?faFileImage:faFilePdf} />
                                                        <IonItem color="light" href={item} target="_blank" rel="noopener noreferrer" lines="none">
                                                            <IonLabel>{filename}</IonLabel>
                                                        </IonItem>
                                                    </IonItem>                                                                                                            
                                                )
                                            }):null
                                         }
                                        
                                            </div>                                            
                                        </IonCol>                                            
                                        </IonRow>
                                </>
                            )
                        })}
                    </IonGrid>                          
                    </div>
                </div>
{/*                 <div style={{ display: "flex" }}>
                    {renderImages()}
                </div> */}

                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>

                    <label htmlFor="icon-button-file">
                    <MdOutlineAttachEmail style={{ fontSize: "30px", color: "#4D4D4D" }} />
                    </label>
                    <input data-testid="TMESSAGEIMAGE" onChange={handleFileUpload} style={{ visibility: "hidden", width: '0px' }} accept={allowedImageType} multiple id="icon-button-file" type="file" />

                    <div style={{ border: "1px solid #999999", borderRadius: "5px", width: "100%", marginLeft: "30px", }}>
                        <IonInput data-testid="TMESSAGETEXT" onIonChange={(e) => {
                            setPlainText(e.detail.value)
                        }} style={{ cursor: "pointer" }} label="Solid input" labelPlacement="floating" fill="outline" placeholder="Message..." value={plaintext}></IonInput>
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                        <IonButton data-testid="TSENDMESSAGE" onClick={createMessage}>send</IonButton>
                    </div>

                </div>
                <div>
                <IonRow class="ion-justify-content-end">
                {fileList.map((selectedFile, index) => {
                    return(
                            <IonCol class='ion-no-margin ion-no-padding ion-padding-end' size="auto">
                                <IonItem color="light"  lines="none">
                                <IonLabel data-testid={"TFILENAME"+"_"+index}>{selectedFile}</IonLabel>
                                <IonButton size="small" fill="clear" onClick={(e) => handleFileDelete(selectedFile)}>
                                <IonIcon icon={close}></IonIcon>
                                </IonButton>
                                </IonItem>
                            </IonCol>    
                    )
                })}
                </IonRow>  
                </div> 
           
            </div>}

        </div>
    )
}

export default Messages