import { FiList } from "react-icons/fi"
import { FiMessageSquare } from "react-icons/fi"
import { AiOutlineSync } from "react-icons/ai"
import { IoMdPerson } from "react-icons/io"
import { BsFillPeopleFill } from "react-icons/bs"
import { RxHome } from "react-icons/rx";
import { MdOnDeviceTraining } from "react-icons/md";
import { CiStethoscope } from "react-icons/ci";
import { MdMedicalInformation } from "react-icons/md";
import { RiDeleteBin2Line } from "react-icons/ri";

export const dashOptions = [
    {
        "id": '1',
        "title": "eCRF",
        "logo":<FiList />,
        "route":"ecrf",
        "role": ["ecrfadmin","clinician"]
    },
    {
        "id": '2',
        "title": "Data Sync",
        "logo":<AiOutlineSync/>,
        "route":"datasync",
        "role": ["clinician"]
    },
    {
        "id": '3',
        "title": "Patient Log",
        "logo":<IoMdPerson/>,
        "route":"patientlog",
        "role": ["clinician"]
    },
    {
        "id": '4',
        "title": "Messages",
        "logo":<FiMessageSquare/>,
        "route":"messages",
        "role": ["clinician"]
    },
    {
        "id": '5',
        "title": "Patient Dashboard",
        "logo":<BsFillPeopleFill/>,
        "route":"patientdashboard",
        "role": ["clinician"]
    },
    {
        "id": '6',
        "title": "Home",
        "logo":<RxHome/>,
        "route":"home",
        "role": ["admin"]
    },
    {
        "id": '7',
        "title": "Register Device",
        "logo":<MdOnDeviceTraining/>,
        "route":"registerDevice",
        "role": ["admin"]
    },
    {
        "id": '8',
        "title": "Register Patient",
        "logo":<BsFillPeopleFill/>,
        "route":"registerPatient",
        "role": ["admin"]
    },
    {
        "id": '9',
        "title": "Register Clinician",
        "logo":<CiStethoscope/>,
        "route":"registerClinician",
        "role": ["admin"]
    },
    {
        "id": '10',
        "title": "Assign Device",
        "logo":<MdMedicalInformation/>,
        "route":"assignDevice",
        "role": ["admin"]
    },
    {
        "id": '11',
        "title": "Revoke",
        "logo":<RiDeleteBin2Line/>,
        "route":"revoke",
        "role": ["admin"]
    },
]