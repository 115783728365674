import React, {useState, useContext, useEffect, Fragment} from 'react';
import "../styles/registerClinician.css";
import clinicianModel from "../model/clinician_model";
import {IonButton, IonCardContent, IonTitle} from "@ionic/react";
import {handleRegisterUser, getFilteredUsers} from "../service/adminUI";
import registerUserModel from "../model/registerUserModel";
import AppContext from "./AppContext";
import {displayError, getPattern, hideError, mapFormDataWithModel} from "../util/dynamicForm";
import * as _ from "lodash";
import { updateUserModel } from '../model/registerUserModel';


let uniqueKey = 'email';
let userid = '';

const RegisterClinician = props => {
    const {bckimgChange, setRegisterToast} = props;
    const TOAST_ERROR_CSS = 'toast' + ' ' + 'toast-error';
    const TOAST_SUCCESS_CSS = 'toast' + ' ' + 'toast-success';
    const {appData, pageData, setPageData} = useContext(AppContext);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [clinicianVal, setClinicianVal] = useState([]);
    const [registerRequest, setRegisterRequest] = useState(false);
    const [cancelRequest, setCancelRequest] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [registerLabel, setRegisterLabel] = useState("Register");
    let clinician = [];

    useEffect(() => {
        bckimgChange('registerClinician.png');
        setClinicianVal(clinician);
    }, [])

    useEffect(() => {
        if (registerRequest) {
            handleRegisterClinicianRequest();            
        }
        if (cancelRequest) {
            clearForm();
            setCancelRequest(false)
        }
    }, [registerRequest, cancelRequest]);

    const handleEyeIconOnClick = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleRegisterClinicianRequest = async () => {
        const toastParams = await handleRegisterClinicianOnClick();
        if (toastParams) {
            setRegisterToast(toastParams);
        }
        setCancelRequest(false)
    }
    
    const handlerCancelFormOnClick = () => {
        setCancelRequest(true);
    }

    const handleRegisterOnClick = () => {
        handleRegisterClinicianRequest();    
      setRegisterRequest(true);
    }

    const validateClinicianData = () => {
        let validationResult = true;
        const clinicianData = [...clinicianVal];
        for (let i = 0; i < clinicianData.length; i++) {
            if (clinicianData[i].pattern == "password") {
                if (clinicianData[i].value) {
                    clinicianData[i].isValid = true;
                    hideError(clinicianData[i].key);
                } else {
                    clinicianData[i].isValid = false;
                    validationResult = false;
                    displayError(clinicianData[i].key);
                }
            } else if (clinicianData[i].pattern == "array" || Array.isArray(clinicianData[i].value)) {
                if (clinicianData[i].value && clinicianData[i].value.length > 0) {
                    clinicianData[i].isValid = true;
                    hideError(clinicianData[i].key);
                } else {
                    clinicianData[i].isValid = false;
                    validationResult = false;
                    displayError(clinicianData[i].key);
                }
            } else if (clinicianData[i].pattern == 'default') {
                if (clinicianData[i].value) {
                    clinicianData[i].isValid = true;
                    hideError(clinicianData[i].key);
                } else {
                    clinicianData[i].isValid = false;
                    validationResult = false;
                    displayError(clinicianData[i].key);
                }
            } else {
                if (clinicianData[i].value && clinicianData[i].value.match(clinicianData[i].pattern)) {
                    clinicianData[i].isValid = true;
                    hideError(clinicianData[i].key);
                } else {
                    clinicianData[i].isValid = false;
                    validationResult = false;
                    displayError(clinicianData[i].key);
                }
            }
        }
        setClinicianVal(clinicianData);
        return validationResult;
    }

    const validateExistingClinicianData = () => {
        let validationResult = true;
        const clinicianData = [...clinicianVal];
        for (let i = 0; i < clinicianData.length; i++) {
            //iterate through inputs that are set
            if (clinicianData[i].value != '') {
                if (clinicianData[i].pattern == "array" || Array.isArray(clinicianData[i].value)) {
                    if (clinicianData[i].value && clinicianData[i].value.length > 0) {
                        clinicianData[i].isValid = true;
                        hideError(clinicianData[i].key);
                    } else {
                        clinicianData[i].isValid = false;
                        validationResult = false;
                        displayError(clinicianData[i].key);
                    }
                } else {
                    if (clinicianData[i].value && clinicianData[i].value.match(clinicianData[i].pattern)) {
                        clinicianData[i].isValid = true;
                        hideError(clinicianData[i].key);
                    } else {
                        clinicianData[i].isValid = false;
                        validationResult = false;
                        displayError(clinicianData[i].key);
                    }
                }
            }
        }
        setClinicianVal(clinicianData);
        return validationResult;
    }

//register clinician
    const handleRegisterClinicianOnClick = async () => {
        userid = '';
        let auniqueObj = _.filter(clinicianVal, function (o) { return o.key == uniqueKey });
        let uniqueObj = !_.isEmpty(auniqueObj) ? auniqueObj[0] : null;
        if (!_.isEmpty(uniqueObj) && uniqueObj?.value != '' && uniqueObj.value.match(uniqueObj?.pattern)) {
            //fetch the entity
            let entityVal = await getFilteredUsers(appData.accessToken, uniqueObj?.value);
            if (!_.isEmpty(entityVal) && entityVal[0]?.attributes && entityVal[0]?.attributes.type && entityVal[0]?.attributes.type[0] == 'clinician') {
                userid = entityVal[0].id;
            }
        }


        if (userid != '') {
            if (validateClinicianData()) {
                const finalModel = mapFormDataWithModel(updateUserModel, clinicianVal, 'clinician');
                const result = await handleRegisterUser(finalModel.attributes.type, finalModel, appData.accessToken, userid);
                if (result == true) {
                    clearForm();
                    return { css: TOAST_SUCCESS_CSS, message: "Clinician registration succeeded!" };
                } else {
                    return { css: TOAST_ERROR_CSS, message: "Error: " + result };
                }

            }
        } else {
            if (validateClinicianData()) {
                const finalModel = mapFormDataWithModel(registerUserModel, clinicianVal, 'clinician');
                const result = await handleRegisterUser(finalModel.attributes.type, finalModel, appData.accessToken);
                if (result == true) {
                    clearForm();
                    return { css: TOAST_SUCCESS_CSS, message: "Clinician registration succeeded!" };
                } else {
                    return { css: TOAST_ERROR_CSS, message: "Error: " + result };
                }

            }
        }
    }

    const handleOnInput = async (event) => {
        let value = event.target.value;
        const name = event.target.name;
        const clinicianData = [...clinicianVal];
        for (let i = 0; i < clinicianData.length; i++) {
            if (clinicianData[i].key == name) {
                if (clinicianData[i].pattern == 'array') {
                    value = value.split('\n');
                }
                clinicianData[i].value = value;
            }
        }

        if (name == uniqueKey && value != '') {
            let auniqueObj = _.filter(clinicianData, function (o) { return o.key == uniqueKey });
            let uniqueObj = !_.isEmpty(auniqueObj) ? auniqueObj[0] : null;
            if (!_.isEmpty(uniqueObj) && uniqueObj?.value != '' && uniqueObj.value.match(uniqueObj?.pattern)) {
                //fetch the entity
                let entityVal = await getFilteredUsers(appData.accessToken, uniqueObj?.value);
                if (!_.isEmpty(entityVal) && entityVal[0]?.attributes && entityVal[0]?.attributes.type && entityVal[0]?.attributes.type[0] == 'clinician') {
                    setRegisterLabel("Update");
                    setRegisterToast({css: TOAST_SUCCESS_CSS,message: "Found a Clinician with email " + uniqueObj.value })
                    let username = { firstName: '0', lastName: '0', email: '0' };
                    for (var element in username) {
                        let idx = _.findIndex(clinicianData, function (o) { return o.key == element; });
                        if (idx != -1) {
                            clinicianData[idx].value = entityVal[0][element];
                        }
                    }
                    element = 'phone';
                    let idx = _.findIndex(clinicianData, function (o) { return o.key == element; });
                    if (idx != -1) {
                        clinicianData[idx].value = entityVal[0].attributes[element][0];
                    }
                    element = 'address'
                    idx = _.findIndex(clinicianData, function (o) { return o.key == 'address'; });
                    if (idx != -1) {
                        clinicianData[idx].value = entityVal[0].attributes[element];
                    }
                    element = 'speciality'
                    idx = _.findIndex(clinicianData, function (o) { return o.key == element; });
                    if (idx != -1) {
                        clinicianData[idx].value = entityVal[0].attributes[element];
                    }
                    element = 'hospital'
                    idx = _.findIndex(clinicianData, function (o) { return o.key == element; });
                    if (idx != -1) {
                        clinicianData[idx].value = entityVal[0].attributes[element];
                    }
                }else {
                    setRegisterLabel("Register");
                }

            }




        }
        setClinicianVal(clinicianData);
    };

    const clearForm = () => {
        document.getElementById('clinician-form').reset();
        document.getElementsByTagName('textarea')[0].value = '';
        setClinicianVal(clinician);
        for(let val in clinicianVal) {
            hideError(clinicianVal[val].key);
        }
    }

    return (
        <Fragment>
            <IonTitle className="clinician-title">Clinician Form
            <span
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            className="info-icon"
          >
            &#128712;
          </span>
          {isVisible && (
            <div className="tooltip">
              Form to Register new Clinician. To update existing clinician
              information, enter email of the clinician to be updated. 
            </div>
          )}
            </IonTitle>
            <IonCardContent className="clinician-content">
                <form id="clinician-form" className="clinician-form">
                    {Object.entries(clinicianModel).map((data) => {
                        let idx = _.findIndex(clinicianVal, function(o) { return o.key == data[0]; });
                        return (
                            getPattern(data[0], data[1], 1, handleOnInput, clinician, passwordVisible, handleEyeIconOnClick,idx != -1? clinicianVal[idx].value:clinicianVal)
                        );
                    })}
                </form>
            </IonCardContent>
            <div className='homeMainFooter'>
                    <IonButton
                        class='loginButton'
                        fill='solid'
                        shape='round'
                        onClick={handlerCancelFormOnClick}>
                        Cancel
                    </IonButton>
                    <IonButton
                        class='loginButton'
                        fill='solid'
                        shape='round'
                        onClick={handleRegisterOnClick}>
                        {registerLabel}
                    </IonButton>
                </div>
        </Fragment>
    );
};
export default RegisterClinician;