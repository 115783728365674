import React, { useEffect, useState } from 'react'
import { useAppData } from '../../context/appContext';
// import DTAL from '../../service/devicesubscription/mockdataservice';
import { IonCol, IonGrid, IonRow, IonItem, IonLabel, IonSpinner } from '@ionic/react';
import moment from 'moment';
import 'react-circular-progressbar/dist/styles.css';
import "./patientlog.css"
import { useContext } from 'react';
import AppContext from '../../components/AppContext';
import { getHistoricalData } from '../../util/helpers/HistoricalData';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from "lodash";

let patientSymptoms = new Map();
const duControlSubject = new BehaviorSubject(false); //to emit start processing of device updates;
let selectedPatient = "" //patient ID
const Patientlog = ({selectedPatientSubject}) => {
    const appData = useAppData()
    const [symptomsLog, setSymptomsLog] = useState()
    const [isPatientAvailable, setIsPatientAvailable] = useState(false)    
    const [isLoaded,setIsLoaded] = useState(false)
    const [isSymptomAvailable,setIsSymptomAvailable] = useState(true)    
    const {dtalService} = useContext(AppContext)
    
    let stringPatientData = localStorage.getItem("patientData")
    let patients = JSON.parse(stringPatientData)
    let historcalSyncDataSubscription;

    // let symptoms=[]
/*     const getSymptomLog = async (subjectId) => {
        const symptoms = await dtalService.get(`symptom:${subjectId}`)
        setSymptomsLog(symptoms)
     
    } */

    useEffect(() => {
        appData.setSelected("Patient Log")
        const selectedPatientSubscription = selectedPatientSubject.subscribe(selectedPatientId => {
            if(!selectedPatientId) {
                if (appData?.appUserData == null) {
                    //getSymptomLog(patients[0]?.attributes.subjectId)
                    selectedPatient = patients[0]?.attributes.subjectId
                } else {
                    //getSymptomLog(appData?.appUserData.attributes.subjectId)
                    selectedPatient = appData?.appUserData.attributes.subjectId
                }
                } else {
                    selectedPatient =  selectedPatientId;
                }
        
                if(selectedPatient.length) 
                {
                    setIsLoaded(false);
                    setSymptomsLog([]);                    
                    patientSymptoms.clear(); //clear old data
                    historcalSyncDataSubscription = getHistoricalData({
                        dtalService,
                        identifier: "symptom:"+selectedPatient,
                        thingUpdates: patientSymptoms,
                        duControlSubject
                    })
                }
        })



        const duControlSubscription = duControlSubject.subscribe(isSymptomAvailable => {
            if (isSymptomAvailable) {
                setSymptomsLog(Array.from(patientSymptoms.keys()))
                dtalService.unsubscribe('history').then(resp => { });
                duControlSubject.next(false);
                setIsLoaded(true); //set to indicate the data is ready
                if(patientSymptoms.size == 0) {
                   setIsSymptomAvailable(false)     
                } else {
                    setIsSymptomAvailable(true)
                }
            
            } 
        })

    return () => {
      duControlSubscription.unsubscribe();
      selectedPatientSubscription.unsubscribe();
      if(historcalSyncDataSubscription){
        dtalService.unsubscribe('history').then(resp => { });
        historcalSyncDataSubscription?.then(sub => sub.unsubscribe());;
      }
      

    };                   
    }, [])


    return (
        <div data-testid="TPATIENTLOG" className='patientlog_container'>
                    {!isLoaded && <IonSpinner name="crescent"> </IonSpinner>}
                    {!isSymptomAvailable && <IonLabel value={"No symptoms found for" + selectedPatient}> </IonLabel>}
{/*          {isPatientAvailable && <HistoricalData dtalService={dtalService} identifier={"symptom:"+selectedPatient} thingUpdates={patientSymptoms} duControlSubject={duControlSubject}/>}
 */}            {symptomsLog?.map((item, index) => {
                let symptomInfo = patientSymptoms.get(item)
                let date = moment.unix(symptomInfo.features?.content.properties.timeOfOccurence)
                let day = date._d.toString().slice(8, 10)
                let month = date._d.toString().slice(4, 8)
                let time = date._d.toString().slice(16, 24)

                //console.log(time)
                return (
                    symptomInfo.features && <div key={index} className='patientlog_card_container'>
                        <IonGrid >
                            <IonRow>
                                <IonCol size='2'>
                                    <div className='patientlog_carddata_container'>
                                        <p className='patientdata_symptom_logo'>SYMPTOM LOGO</p>
                                        <p data-testid={"TPATIENTLOGDAY_"+index} className='patientdata_day'>{day}</p>
                                        <p data-testid={"TPATIENTLOGMONTH_"+index} className='patientdata_month'>{month}</p>
                                        <p style={{ fontSize: "18px", color: "#535352" }}>{time}</p>
                                    </div>
                                </IonCol>
                                <IonCol size='4'>
                                    <IonGrid>
                                        <IonRow>

                                            <IonCol data-testid={"TPATIENTLOGNAME_"+index} style={{ color: "#333333", fontSize: "14px" }} size='12'>
                                                {symptomInfo.features.content.properties.name}
                                            </IonCol>
                                           
                                        </IonRow>
                                    </IonGrid>
                                </IonCol>
                                <IonCol size='6'>
                                    <div data-testid={"TPATIENTLOGNOTE_"+index} className='patientlog_comments_container' >
                                    {symptomInfo.features.content.properties.note}                                    
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <div className='patientlog_details_container'>
                            <div style={{ width: "100%", height: "100%", border: "1px solid #999999" }}></div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Patientlog