import React from "react";
import {IonCol, IonList, IonRadio, IonRadioGroup, IonRow} from "@ionic/react";
import "../styles/deviceTable.css";

const DeviceTable = (props) => {

    const {data, onRadioChange} = props;

    return (
        <IonList>
            <IonRadioGroup onIonChange={onRadioChange}>
                {data.map((item) => {
                    return (
                        <IonRow className="devices-row">
                            <IonCol className="table-cell radio-button">
                                <IonRadio value={item.attributes.macid + "&" + item.thingId + "&"+ item.attributes.serialnumber} />
                            </IonCol>
                            <IonCol className="table-cell">
                                {item.attributes.serialnumber}
                            </IonCol>
                            <IonCol className="table-cell">
                                {item.attributes.macid}
                            </IonCol>                            
                        </IonRow>
                    );
                })}
            </IonRadioGroup>
        </IonList>
    );
}

export default DeviceTable;