import { EventSourcePolyfill } from 'event-source-polyfill/src/eventsource.min.js'
let BASE_URL = "https://evaluate.samarthii.com/api/2/things"

export const handleSubscribe = async (accesstoken) => {
    var eventSourceInitDict = {
      headers: {
        Authorization: `Bearer ${accesstoken}`,
        // "Content-Type": "application/merge-patch+json",
      },
    };
    let source = new EventSourcePolyfill(
      "https://evaluate.samarthii.com/api/2/things?ids=pacemaker:00-01-02-03-04-05&fields=thingId,attributes,features,_revision,_modified",
      eventSourceInitDict
    );
    source.onmessage = function (event) {
      console.log(event.data, "ye event data hai zzzzzzzzzzzzzzz");
    };
  };