import React, { useContext, useEffect, useState, Fragment } from "react";
import "../styles/registerDevice.css";
import deviceModel from "../model/device_model";
import { handleRegisterDevice } from "../service/adminUI";
import { IonGrid, IonButton, IonCardContent, IonTitle } from "@ionic/react";
import registerDeviceModel from "../model/registerDeviceModel";
import AppContext from "./AppContext";
import {
  getPattern,
  mapFormDataWithModel,
  hideError,
  displayError,
} from "../util/dynamicForm";
import * as _ from "lodash";

let uniqueKey = "macid";

const RegisterDevice = (props) => {
  const { bckimgChange, setRegisterToast } = props;
  const TOAST_ERROR_CSS = "toast" + " " + "toast-error";
  const TOAST_SUCCESS_CSS = "toast" + " " + "toast-success";
  const { appData, dtalService, pageData, setPageData } =
    useContext(AppContext);
  const DATE_PATTERN = /^\d{4}(\/)\d{2}(\/)\d{2}$/;
  const [deviceVal, setDeviceVal] = useState([]);
  const [registerRequest, setRegisterRequest] = useState("");
  const [cancelRequest, setCancelRequest] = useState("");
  const [registerLabel, setRegisterLabel] = useState("Register");
  const [isVisible, setIsVisible] = useState(false);
  const device = [];

  useEffect(() => {
    bckimgChange("registerDevice.png");
    setDeviceVal(device);
  }, []);

  useEffect(() => {
    if (registerRequest === true) {
      handleRegisterDeviceRequest();
    }
    if (cancelRequest === true) {
      clearForm();
      setRegisterRequest(false);
    }
  }, [registerRequest, cancelRequest]);

  const handleRegisterDeviceRequest = async () => {
    const toastParams = await handleRegisterDeviceOnClick();
    if (toastParams) {
      setRegisterToast(toastParams);
    }
  };

  const handleOnInput = async (event) => {
    let value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;
    const deviceData = [...deviceVal];
    for (let i = 0; i < deviceData.length; i++) {
      if (deviceData[i].key == name) {
        if (deviceData[i].pattern.toString() == DATE_PATTERN.toString()) {
          value = value.split("T")[0].replaceAll("-", "/");
        }
        if (deviceData[i].pattern == "boolean") {
          value = checked;
        }
        deviceData[i].value = value;
      }
    }
    if (name == uniqueKey && value != "") {
      let auniqueObj = _.filter(deviceData, function (o) {
        return o.key == uniqueKey;
      });
      let uniqueObj = !_.isEmpty(auniqueObj) ? auniqueObj[0] : null;
      if (
        !_.isEmpty(uniqueObj) &&
        uniqueObj?.value != "" &&
        uniqueObj.value.match(uniqueObj?.pattern)
      ) {
        //fetch the entity
        let entityVal = await dtalService.get(
          "pacemaker:" + uniqueObj.value + "&fields=attributes"
        );
        if (!_.isEmpty(entityVal) && entityVal[0]?.attributes) {
          setRegisterLabel("Update");
          setRegisterToast({css: TOAST_SUCCESS_CSS,message: "Found a device with macid " + uniqueObj.value })
          for (var attrname in entityVal[0]?.attributes) {
            let idx = _.findIndex(deviceData, function (o) {
              return o.key == attrname;
            });
            if (idx != -1) {
              deviceData[idx].value = entityVal[0].attributes[attrname];
              // let name = deviceData[idx].key;
              // let value = deviceData[idx].value;
              // setInputValues ({
              //     ...inputValues,
              //     [name]: {value},
              // })
            } else {
              if (attrname == "version") {
                //nested data
                let versionmodel = {
                  minor: "sw",
                  major: "sw",
                  hw: "0",
                  fw: "0",
                  implant: "0",
                };
                for (var nestedelement in versionmodel) {
                  idx = _.findIndex(deviceData, function (o) {
                    return o.key == nestedelement;
                  });
                  if (idx != -1) {
                    if (versionmodel[nestedelement] != 0) {
                      deviceData[idx].value =
                        entityVal[0].attributes?.version?.[
                          versionmodel[nestedelement]
                        ][nestedelement];
                    } else {
                      deviceData[idx].value =
                        entityVal[0].attributes?.version?.[nestedelement];
                      if (nestedelement == "implant") {
                        document.getElementsByTagName("ion-toggle")[0].checked =
                          deviceData[idx].value; //NOTE explictly setting the toggle as checked property is not working
                      }
                    }
                    if (
                      deviceData[idx].value &&
                      typeof deviceData[idx].value == "number"
                    ) {
                      deviceData[idx].value = deviceData[idx].value.toString();
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        setRegisterLabel("Register");
      }
    }
    setDeviceVal(deviceData);
  };

  const validateDeviceData = () => {
    let validationResult = true;
    const deviceData = [...deviceVal];
    for (let i = 0; i < deviceData.length; i++) {
      if (
        deviceData[i].pattern == "boolean" ||
        (deviceData[i].value &&
          deviceData[i].value.match(deviceData[i].pattern))
      ) {
        deviceData[i].isValid = true;
        hideError(deviceData[i].key);
      } else {
        deviceData[i].isValid = false;
        validationResult = false;
        displayError(deviceData[i].key);
      }
    }

    setDeviceVal(deviceData);
    return validationResult;
  };

  //register device
  const handleRegisterDeviceOnClick = async () => {
    try {
      if (validateDeviceData()) {
        const finalModel = mapFormDataWithModel(
          registerDeviceModel,
          deviceVal,
          "device"
        );
        const result = await handleRegisterDevice(
          finalModel.attributes.macid,
          finalModel,
          appData.accessToken
        );
        setRegisterRequest(false);
        if (result == true) {
          //clearForm();
          return {
            css: TOAST_SUCCESS_CSS,
            message: "Device registration succeeded!",
          };
        } else {
          return { css: TOAST_ERROR_CSS, message: "Error: " + result };
        }
      }
    } catch (err) {
      console.error("Error: ", err);
      setRegisterRequest(false);
    }
  };

  const handlerCancelFormOnClick = () => {
    setCancelRequest(true);
  };

  const handleRegisterOnClick = () => {
    setRegisterRequest(true);
  };

  const clearForm = () => {
    document.getElementById("device-form").reset();
    document.getElementsByTagName("ion-datetime")[0].value = "";
    document.getElementsByTagName("ion-toggle")[0].checked = false;
    setDeviceVal(device);
    for (let val in deviceVal) {
      hideError(deviceVal[val].key);
    }
  };

  return (
    <Fragment>
      {/* <div data-testid="TGETIONROWDATA" id="scrollableDiv" style={{ height: '80%' }} className='admin_dash_container'> */}
      <IonTitle className="device-title">
        Device Form
        <span
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
          className="info-icon"
        >
          &#128712;
        </span>
        {isVisible && (
          <div className="tooltip">
            Form to Register new Device. To Update existing device information,
            please enter the macid of that device.
          </div>
        )}
      </IonTitle>
      <IonCardContent className="device-content">
        <form id="device-form" className="device-form">
          {Object.entries(deviceModel).map((data) => {
            let idx = _.findIndex(deviceVal, function (o) {
              return o.key == data[0];
            });
            return getPattern(
              data[0],
              data[1],
              1,
              handleOnInput,
              device,
              null,
              null,
              idx != -1 ? deviceVal[idx].value : deviceVal
            );
          })}
        </form>
      </IonCardContent>
      {/* </div>   */}
      <div className="homeMainFooter">
        <IonButton
          class="loginButton"
          fill="solid"
          shape="round"
          onClick={handlerCancelFormOnClick}
        >
          Cancel
        </IonButton>
        <IonButton
          class="loginButton"
          fill="solid"
          shape="round"
          onClick={handleRegisterOnClick}
        >
          {registerLabel}
        </IonButton>
      </div>
    </Fragment>
  );
};
export default RegisterDevice;
