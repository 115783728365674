import React, {Fragment} from "react";
import {IonCheckbox, IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonTextarea, IonTitle, IonToggle} from "@ionic/react";
import {addCircle, alert, calendarOutline, eyeOffOutline, eyeOutline} from "ionicons/icons";
import * as _ from "lodash";

const ERROR_MSG = "Invalid Details entered, please check";
const ALPHA_NUMERIC_PATTERN = /^[0-9a-zA-Z _]+$/;
const DATE_PATTERN = /^\d{4}(\/)\d{2}(\/)\d{2}$/;
//const MAC_ID_PATTERN = /^[0-9A-F]{1,2}([\.:-])(?:[0-9A-F]{1,2}\1){4}[0-9A-F]{1,2}$/g;
const MAC_ID_PATTERN = /^[0-9A-F]{2}([-])(?:[0-9A-F]{2}\1){4}[0-9A-F]{2}$/g;
const VERSION_PATTERN = /^[0-9.]*$/;
const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PHONE_PATTERN = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
const DEFAULT_PATTERN = /.*/; //all any character

export const getPattern = (key, value, level, handleOnInput, model, passwordVisible, handleEyeIconOnClick, defvalue=null) => {
    if (Array.isArray(value)) {
        setData(key, [], 'array', model);
        return <Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="patient-item">
                <IonLabel>{key} :</IonLabel>
                <IonTextarea name={key} type="text" placeholder={value} onInput={handleOnInput} value={defvalue}></IonTextarea>
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>
    }
    if (key == 'password') {
        setData(key, '', 'password', model);
        return <Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="clinician-item">
                <IonLabel>{key} :</IonLabel>
                <IonInput name={key} type={passwordVisible ? 'text' : 'password'} placeholder={value}
                          onInput={handleOnInput} value={defvalue}></IonInput>
                <IonIcon
                    icon={passwordVisible ? eyeOutline : eyeOffOutline}
                    slot='end'
                    onClick={handleEyeIconOnClick}
                />
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>
    }
    if (typeof (value) == 'object') {
        let res = [];
        res.push(<Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="device-label">
                <IonLabel>{key}</IonLabel>
                <IonIcon
                    className="addcircle-icon"
                    icon={addCircle}
                />
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>);
        for (let data in value) {
            let inputvalue = defvalue;
            if(!_.isEmpty(defvalue)) { //NOTE defvalue is of deviceVal type, hence any change in deviceVal type, the following logic to be revisited
                let idx = _.findIndex(defvalue, function(o) { return o.key == data; });
                if(idx != -1) inputvalue = defvalue[idx].value
            }
            res.push(getPattern(data, value[data], level + 1, handleOnInput, model, passwordVisible, handleEyeIconOnClick,inputvalue));
        }
        return res;
    }
    if (typeof (value) == 'boolean') {
        setData(key, value, 'boolean', model);
        return (<Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="device-item">
                <IonLabel>{key} :</IonLabel>
                <IonToggle name={key} defaultValue={value} className="device-toggle"
                           onIonChange={handleOnInput}> checked={defvalue} </IonToggle>
            
                        
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>);
    }
    if (value.match(ALPHA_NUMERIC_PATTERN)) {
        setData(key, '', ALPHA_NUMERIC_PATTERN, model);
        return (<Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="device-item">
                <IonLabel>{key} :</IonLabel>
                <IonInput name={key} type="text" placeholder={value} onInput={handleOnInput} value={defvalue}></IonInput>
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>);
    }
    if (value.match(MAC_ID_PATTERN)) {
        setData(key, '', MAC_ID_PATTERN, model);
        return (<Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="device-item">
                <IonLabel>{key} :</IonLabel>
                <IonInput name={key} type="text" placeholder={value} onInput={handleOnInput} value={defvalue}></IonInput>
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>);
    }
    if (value.match(VERSION_PATTERN)) {
        setData(key, '', VERSION_PATTERN, model);
        return (<Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="device-item">
                <IonLabel>{key} :</IonLabel>
                <IonInput name={key} type="text" placeholder={value} onInput={handleOnInput} value={defvalue}></IonInput>
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>);
    }
    if (value.match(DATE_PATTERN)) {
        setData(key, '', DATE_PATTERN, model);
        return (<Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="device-item">
                <IonLabel style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{key} :
                    <IonDatetime key={key} name={key} displayFormat="YYYY/MM/DD" placeholder={value}
                                 className="device-date-value" onIonChange={handleOnInput} value={defvalue}/>
                    <IonIcon
                        className="calendar-icon"
                        icon={calendarOutline}
                    />
                </IonLabel>
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>);
    }
    if (value.match(PHONE_PATTERN)) {
        setData(key, '', PHONE_PATTERN, model);
        return <Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="patient-item">
                <IonLabel>{key} :</IonLabel>
                <IonInput name={key} type="number" placeholder={value} onInput={handleOnInput} value={defvalue}></IonInput>
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>
    }
    if (value.match(EMAIL_PATTERN)) {
        setData(key, '', EMAIL_PATTERN, model);
        return <Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="patient-item">
                <IonLabel>{key} :</IonLabel>
                <IonInput name={key} type="email" placeholder={value} onInput={handleOnInput} value={defvalue}></IonInput>
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>
    }
    if (key) {
        setData(key, '', DEFAULT_PATTERN, model);
        return (<Fragment key={key}>
            <IonItem style={{marginLeft: `${level}rem`}} className="patient-item">
                <IonLabel>{key} :</IonLabel>
                <IonInput name={key} type="text" placeholder={value} onInput={handleOnInput} value={defvalue}></IonInput>
            </IonItem>
            {getErrorTitle(key)}
        </Fragment>);
    }
}

const setData = (key, value, pattern, model) => {
    const temp = {};
    temp.key = key;
    temp.value = value;
    temp.pattern = pattern;
    model.push(temp);   
}

const getErrorTitle = (id) => {
    return (<IonTitle id={id} style={{display: 'none'}}>
        <IonIcon icon={alert}/>
        {ERROR_MSG}
    </IonTitle>);
}

export const mapFormDataWithModel = (modelData, value, type) => {
    for (let data in modelData) {
        //if (typeof (modelData[data]) == 'object') {
        if (_.isPlainObject(modelData[data])) {    
            mapFormDataWithModel(modelData[data], value);
        } else {
            for (let val of value) {
                if (val.key == data) {
                    modelData[data] = val.value;
                }
            }
        }
    }
    if (modelData.hasOwnProperty("attributes") &&  type == "patient") {
        if (modelData.attributes.hasOwnProperty("hospital")) {
            delete modelData.attributes.hospital;
        }
        if (modelData.attributes.hasOwnProperty("speciality")) {
            delete modelData.attributes.speciality;
        }
    }
    if (type == "patient") modelData.attributes.type = "patient";
    else if (type == "clinician") modelData.attributes.type = "clinician";
    return modelData;
}

export const displayError = (id) => {
    document.getElementById(id).style = "display: visible; color: red; font-family: PoppinsRegular; font-size: 0.8rem";
}

export const hideError = (id) => {
    document.getElementById(id).style = "display: none";
}