import React, { useState, useEffect, useContext } from 'react'
import Enrollment from './components/enrollment/enrollment'
import Implant from './components/implant/implant'
import Followup from './components/followup/followup'
import Protocoldeviation from './components/protocoldeviation/protocoldeviation'
import Adversefields from './components/adversefields/adversefields'
import Preimplant from './components/preimplant/preimplant'
import Studycompletion from './components/studycompletion/studycompletion'
import Devicemalfunction from './components/devicemalfunction/devicemalfunction'
import { useAppData } from '../../context/appContext'
import { ecrfTabs } from '../../utils/jsonFiles/ecrftabs'
import { IonButton, IonCol, IonGrid, IonInput, IonRow } from '@ionic/react'
import { MdModeEditOutline } from "react-icons/md"
import { AiTwotoneDelete } from "react-icons/ai"
import { MdOutlineCancel } from "react-icons/md"
import AppContext from "../../components/AppContext";
import { DTAL } from '../../service/DTAL'
import "./ecrf.css"
import { calyanuser,followUpValues } from '../../util/config'
import { getUserInfoByName } from '../../service/adminUI';
import { IonItem, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import * as _ from "lodash";

let dtalService = new DTAL(null);
let aPatients = []; //store the patients enrolled

const Ecrf = () => {
    const [selectedTab, setSelectedTab] = useState()
    const [followUpDuration,setFollowUpDuration] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [selectedPatient, setSelectedPatient] = useState()
    const [patientId, setPatientId] = useState()
    const [siteId, setSiteId] = useState()
    const [clinicianEmailId, setClinicianEmailId] = useState()
    const [dob, setDob] = useState()    
    const [gender, setGender] = useState()
    const [eligibility, setEligibility] = useState()
    const [permission, setPermission] = useState({})    
    const [editMode, setEditMode] = useState(false)   
    const [isPatientAvailable, setIsPatientAvailable] = useState(false)
    const [isPatientAdded, setIsPatientAdded] = useState(false)
    const { userData } = useContext(AppContext);
    //console.log(siteId, patientId, "hallabaloo")
    const appData = useAppData()

    let accessRights = {
        //give policy RW and thing RW to logged in admin user credentials
        policyowner: { userId: userData.userId, emailId: userData.emailId, type: userData.userType },
        rwowners: [{ userId: userData.userId, emailId: userData.emailId, type: userData.userType }],
        ronlyowners: [{ userId: calyanuser.userId, emailId: calyanuser.emailId, type: calyanuser.userType }], //read access to adminUI user for mapping to patient
    };

    const setSubject = async () => {
        //fetch eCRF_subjects
        aPatients = await dtalService.fetch('eCRF_subjects');
        aPatients.items = _.filter(aPatients.items,function (o) { return o.attributes?.patientId != null; }); //filter out null or undefined patientId
        if (aPatients.items.length > 0) {
            setIsPatientAvailable(true);
/*             //list the patient 
            if (appData?.appUserData == undefined) {
                setSubjectId(aPatients.items[0].attributes.patientId)
            }
            else {
                setSubjectId(appData?.appUserData?.attributes?.subjectId)
            } */
        }
    }

    const setPatientData = () => {
        setPatientId(selectedPatient?.attributes.patientId)
        setSiteId(selectedPatient?.attributes.siteId)
        setDob(selectedPatient?.attributes.dob)
        setGender(selectedPatient?.attributes.gender)
        setEligibility(selectedPatient?.attributes.eligibility)
        setClinicianEmailId(selectedPatient?.attributes.clinicianEmailId)
    }

    //console.log(patientId, siteId, gender, consentDate, eligibility, "all data")
    useEffect(() => {
        appData.setSelected("eCRF")
        //setPatientData()
        setSubject()
    }, [])

    const open = (isEdit) => {
        if(isEdit) {
            setPatientData();
        } else {
            setPatientId(null)
            setSiteId(null)
            setDob(null)
            setGender(null)
            setEligibility(null)
            setClinicianEmailId(null) 
            setIsPatientAdded(false)           
        }
        setEditMode(isEdit)
        setOpenModal(true)
    }
    const close = () => {
        setOpenModal(false)
    }

    const  handleFollowupSelection = async (e) =>{
        if(!_.isEmpty(e.target.value)) {
            setFollowUpDuration(e.target.value);
            
        }

    }

    const  handleFollowupFocus = async (e) =>{
        setSelectedTab('FOLLOW UP');

    }    


    const updateROaccessRight = async (emailId) => {

        if(emailId) {
            let token = localStorage.getItem("access_token")
            let clinicianInfo = await getUserInfoByName(token,emailId)
            if(clinicianInfo) {
                //add clinician to accessRights for read-only to eCRF data
                accessRights.ronlyowners.push({userId:clinicianInfo.id,emailId:clinicianInfo.email,type:clinicianInfo.attributes.type[0]})
        }
    }
}

    const handlePatientSelection = async (patientinfo) =>{
        setSelectedPatient(patientinfo); 
        await updateROaccessRight(patientinfo.attributes.clinicianEmailId); //to enable eCRF data read access to clinician
        setPermission(accessRights);
    }
    const saveUser = async () => {
        let patientPayload = {
            attributes: {
                patientId: patientId,
                siteId: siteId,
                clinicianEmailId,
                dob,
                gender: gender,
                eligibility: eligibility
            }
        }

        await updateROaccessRight(clinicianEmailId); 
        await dtalService.create(`eCRF_subjects:${patientId}`,patientPayload, accessRights);

        //add to the list if this is a new patient
        let alreadyExistingPatient = _.find(aPatients.items,function (o) { return o.attributes.patientId == patientPayload.attributes.patientId; });

        if(alreadyExistingPatient) {
            _.merge(alreadyExistingPatient, patientPayload);
        } else {
            aPatients.items.push(patientPayload);
            setIsPatientAdded(true);
        }

        close()
    }

    console.log(selectedPatient, "apatients")

    return (
        <div>
            <div style={{ display: "flex", borderBottom: "1px solid #535352", textAlign: "center", marginBottom: '10px' }}>
                {ecrfTabs.map((tab, index) => {
                    return (
                        <div style={{ display: "flex" }}>
                        {tab.title!='FOLLOW UP' && <div key={index} data-testid={tab.title} className={tab.title == selectedTab ? "selectedTab" : "unselectedTab"} onClick={(e) => { setSelectedTab(tab.title); }}>{tab.title}</div>}
                            {tab.title=='FOLLOW UP' && <div>
                    <select data-testid="FOLLOW UP_SELECT" onChange={(e)=> {handleFollowupSelection(e)}} onFocus={(e)=> {handleFollowupFocus(e)}} className={selectedTab == "FOLLOW UP" ? "selectedOption" : "unselectOption"}>
                    <option className="selectOption" value="" disabled selected>FOLLOW UP</option>
                    {followUpValues.map(val => (
                    <option className="selectOption" value={val.recId}>{val.desc}</option>
                    ))}
                </select>
                            
                            </div>}
                        <div style={{ height: "100%", width: "1px", backgroundColor: "#535352" }}></div>    

                        </div>
                    )
                })}

                <div style={{ height: "100%", width: "1px", backgroundColor: "#535352" }}></div>
            </div>
            {(userData.userType == 'ecrfadmin') && !selectedTab && <button className='ecrf_add_patient' onClick={()=> open(false)}>Add patient</button>}
            {selectedTab && <button className='ecrf_add_patient' onClick={()=> setSelectedTab(null)}>List patient</button>}

            <h4 style={{ paddingBottom: "18px", color: "#535352" }}>
                PATIENT INFORMATION
            </h4>
            {selectedTab && <div style={{ borderBottom: "1px solid #535352" }}>
                <IonGrid style={{ width: "100%" }}>
                    <IonRow >
                        <IonCol className='ecrf_columns_spacing' size="auto">
                            <IonInput data-testid="TECRFSUBJECTID" value={selectedPatient?.attributes.patientId} className='ecrf_patientname' label="Outline input" fill="outline" readonly={true}></IonInput>
                            <div style={{ fontSize: "13px", color: "#535352" }}>PATIENT ID</div>
                        </IonCol>
                        <IonCol className='ecrf_columns_spacing' size="auto">
                            <IonInput data-testid="TECRFSITEID" value={selectedPatient?.attributes.siteId} className='ecrf_sitename' label="Outline input" fill="outline" readonly={true}></IonInput>
                            <div style={{ fontSize: "13px", color: "#535352" }}>SITE ID</div>
                        </IonCol>                        
                        <IonCol className='ecrf_columns_spacing'  size="auto">
                            <input  data-testid="TECRFDOB" className='ecrf_date' type="date" value={selectedPatient?.attributes?.dob} disabled></input>
                            <div style={{ fontSize: "13px", color: "#535352" }}>
                                DATE OF BIRTH
                            </div>
                        </IonCol>
{/*                         <IonCol className='ecrf_columns_spacing'  size="auto">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ fontSize: "13px", color: "#535352", paddingRight: "20px" }}>CONSENT SIGNED</div>
                                <input checked={consentDate ? "checked" : ''} className='ecrf_radio' name="consent" type="radio" />
                                <div style={{ paddingLeft: "5px", color: "#535352" }}>Yes</div>
                                <input data-testid="TECRFCONSENTNO" name="consent" style={{ marginLeft: "5px" }} type="radio" />
                                <div style={{ paddingLeft: "5px", color: "#535352" }}>No</div>
                            </div>
                        </IonCol>
                        <IonCol className='ecrf_columns_spacing' size="auto">
                            <input value={selectedPatient?.attributes.consentDate} className='ecrf_date' type="date"></input>
                            <div style={{ fontSize: "13px", color: "#535352" }}>
                                DATE OF CONSENT SIGNED
                            </div>
                        </IonCol> */}
                        <IonCol className='ecrf_columns_spacing'  size="auto">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ fontSize: "13px", color: "#535352", paddingRight: "20px" }}>SEX</div>
                                <input defaultChecked={selectedPatient?.attributes?.gender == "Male" ? "checked" : ""} name="gender" type="radio" disabled/>
                                <div style={{ paddingLeft: "5px", color: "#535352" }}>M</div>
                                <input defaultChecked={selectedPatient?.attributes?.gender == "Female" ? "checked" : ""} name="gender" style={{ marginLeft: "5px" }} type="radio" disabled/>
                                <div style={{ paddingLeft: "5px", color: "#535352" }}>F</div>
                            </div>
                        </IonCol>
                        <IonCol className='ecrf_columns_spacing' size="auto">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ fontSize: "13px", color: "#535352", paddingRight: "20px" }}>18 OR OLDER</div>
                                <input defaultChecked={selectedPatient?.attributes?.eligibility == "Yes" ? "checked" : ""} name="legal age" type="radio" disabled/>
                                <div style={{ paddingLeft: "5px", color: "#535352" }}>Yes</div>
                                <input defaultChecked={selectedPatient?.attributes?.eligibility == "No" ? "checked" : ""} name="legal age" style={{ marginLeft: "5px" }} type="radio" disabled/>
                                <div style={{ paddingLeft: "5px", color: "#535352" }}>No</div>
                            </div>
                        </IonCol>
{/*                         <IonCol className='ecrf_columns_spacing'size="auto">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ fontSize: "13px", color: "#535352", paddingRight: "20px" }}>WILLING AND CAPABLE OF PROVIDING INFORMED CONSENT</div>
                                <input checked={consentDate ? "checked" : ''} name="Willing consent" type="radio" />
                                <div style={{ paddingLeft: "5px", color: "#535352" }}>Yes</div>
                                <input data-testid="TECRFWILLINGNO" name="Willing consent" style={{ marginLeft: "5px" }} type="radio" />
                                <div style={{ paddingLeft: "5px", color: "#535352" }}>No</div>
                            </div>
                        </IonCol> */}
                    </IonRow>
                </IonGrid>
            </div>}
            {!selectedTab &&  isPatientAvailable && <div className='patientinfo_height'>
                {aPatients?.items.map((item,index)=>{
                    return(   <IonItem button class="selectedBtnColor" color="light" lines="none" data-testid={"TPATIENTIDX_"+index} onClick={()=>handlePatientSelection(item)} style={{ display: "flex", justifyContent: 'center' }}>
                    <div style={{ width: '100%', height: "100%", borderRadius: "5px", padding: "15px", }}>
                     
                        <div className='datasync_log_container'>
                            <div style={{ display: "flex", width: "30%", justifyContent: "space-between" }}>
                                <div data-testid={"TPATIENT_"+index}  className={selectedPatient==item?'patientinfo_selectedEntry':'datasync_sync_id'} >{item?.attributes.patientId}</div>
{/*                                 <div className='datasync_sync_id'>HCA HEALTHCARE (NASHVILLE)</div> */}

                            </div>

                                {selectedPatient==item? <div style={{ display: "flex" }}>
                                    <div>
                                        <MdModeEditOutline onClick={()=> open(true)} style={{ fontSize: "30px" }} />
                                    </div>
                                    <div style={{ paddingLeft: "20px" }}>
                                        <AiTwotoneDelete style={{ fontSize: "30px" }} />
                                    </div>
                                </div>:null}
                            </div>
                        </div>
                    </IonItem>)
                })}

            </div>}
            {/*CFG revisit below checks if the tab name gets changed */}    
            {selectedTab == "ENROLLMENT" && <Enrollment subjectId={selectedPatient?.attributes.patientId} accessRights={permission} patientInfo={selectedPatient}/>}
            {selectedTab == "PRE IMPLANT VISIT" && <Preimplant subjectId={selectedPatient?.attributes.patientId} accessRights={permission} />}
            {selectedTab == "IMPLANT VISIT" && <Implant subjectId={selectedPatient?.attributes.patientId} accessRights={permission} f />}
            {selectedTab == "FOLLOW UP" && followUpDuration && <Followup subjectId={selectedPatient?.attributes.patientId} accessRights={permission} followUpDuration={followUpDuration} />}
            {selectedTab == "ADVERSE EVENTS" && <Adversefields subjectId={selectedPatient?.attributes.patientId} accessRights={permission} />}
            {selectedTab == "PROTOCOL DEVIATION" && <Protocoldeviation subjectId={selectedPatient?.attributes.patientId} accessRights={permission} />}
            {selectedTab == "STUDY COMPLETION /TERMINATION" && < Studycompletion subjectId={selectedPatient?.attributes.patientId} accessRights={permission} />}
            {selectedTab == "DEVICE MALFUNCTION" && < Devicemalfunction subjectId={selectedPatient?.attributes.patientId} accessRights={permission} />}
            {
                /*add patient form*/
                openModal == true ? <div style={{ position: "absolute", height: "550px", width: "750px",zIndex:"100", background: "#E6E6E6", border: "1px solid black", left: "16px", right: "10%", marginLeft: "auto", marginRight: "auto", bottom: "19%" }}>
                    <div style={{ width: "100%", height: "60px", background: "#4D4D4D", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ color: "#E6E6E6" }}>
                            PATIENT INFORMATION
                        </div>
                        <div onClick={close}>
                            <MdOutlineCancel style={{ fontSize: "30px", color: "#E6E6E6" }} />
                        </div>
                    </div>
                    <div style={{ padding: "20px", marginTop: "20px" }}>
                        <IonInput value={patientId} onIonChange={e =>  setPatientId(e.target.value) } style={{ border: "1px solid #999999", width: "342px", height: "35px", borderRadius: "5px" }} label="Outline input" fill="outline" placeholder="Enter SubjectId"></IonInput>
                        <div style={{ fontSize: "17px", color: "#535352", paddingTop: "5px", paddingBottom: '15px' }}>PATIENT ID</div>
                        <IonInput value={siteId} onIonChange={(e) => { setSiteId(e.target.value) }} style={{ border: "1px solid #999999", width: "342px", height: "35px", borderRadius: "5px" }} label="Outline input" fill="outline" placeholder="Enter SiteId"></IonInput>
                        <div style={{ fontSize: "17px", color: "#535352", paddingTop: "5px", paddingBottom: '15px' }}>SITE ID</div>
                        <input value={dob} onChange={(e) => { setDob(e.target.value) }} style={{ width: "220px", height: "35px", borderRadius: "5px", backgroundColor: "transparent", border: "1px solid #999999", color: "#535352" }} type="date"></input>
                        <div style={{ fontSize: "17px", color: "#535352", paddingTop: "5px", paddingBottom: '15px' }}>
                            DATE OF BIRTH
                        </div>
                        <input value={clinicianEmailId} onChange={(e) => { setClinicianEmailId(e.target.value) }} style={{ width: "220px", height: "35px", borderRadius: "5px", backgroundColor: "transparent", border: "1px solid #999999", color: "#535352" }} placeholder="Enter Dr's email" type="text"></input>
                        <div style={{ fontSize: "17px", color: "#535352", paddingTop: "5px", paddingBottom: '15px' }}>
                            DOCTOR EMAILID
                        </div>                        
                        <div style={{ display: "flex", alignItems: "center", paddingTop: "5px", paddingBottom: '15px' }}>
                            <div style={{ fontSize: "17px", color: "#535352", paddingRight: "20px" }}>SEX</div>
                            <input checked={gender == "Male"} onChange={(e) => { setGender(e.target.value) }} value="Male" name="gender" type="radio" />
                            <div style={{ paddingLeft: "5px", color: "#535352" }}>M</div>
                            <input checked={gender == "Female"} onChange={(e) => { setGender(e.target.value) }} value="Female" name="gender" style={{ marginLeft: "5px" }} type="radio" />
                            <div style={{ paddingLeft: "5px", color: "#535352" }}>F</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", paddingTop: "5px", paddingBottom: '15px' }}>
                            <div style={{ fontSize: "17px", color: "#535352", paddingRight: "20px" }}>18 OR OLDER</div>
                            <input checked={eligibility == "Yes"} value="Yes" onChange={(e) => { setEligibility(e.target.value) }} name="legal age" type="radio" />
                            <div style={{ paddingLeft: "5px", color: "#535352" }}>Yes</div>
                            <input checked={eligibility == "No"} value="No" onChange={(e) => { setEligibility(e.target.value) }} name="legal age" style={{ marginLeft: "5px" }} type="radio" />
                            <div style={{ paddingLeft: "5px", color: "#535352" }}>No</div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <IonButton onClick={()=> setOpenModal(false)} style={{ width: "220px", textAlign: "center", height: "40px", backgroundColor: "#4D4D4D", color: "white", fontSize: "13px", letterSpacing: "0.2em", borderRadius: "5px", marginTop: "20px", marginBottom: "20px" }}>CANCEL</IonButton>
                            <IonButton onClick={saveUser} style={{ width: "220px", textAlign: "center", height: "40px", backgroundColor: "#4D4D4D", color: "white", fontSize: "13px", letterSpacing: "0.2em", borderRadius: "5px", marginTop: "20px", marginBottom: "20px" }}>SAVE</IonButton>
                        </div>
                    </div>
                </div> : ""
            }

        </div>
    )
}

export default Ecrf